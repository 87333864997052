
        .errorContainer {
            margin: 0;
            padding: 0;
            font-family: 'Arial', sans-serif;
            background-color: #f3f4f6;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            text-align: center;
            color: #333;
        }

        .container {
            max-width: 600px;
            padding: 20px;
        }

        .container h1 {
            font-size: 8rem;
            margin: 0;
            color: #ff6b6b;
        }

        .container h2 {
            font-size: 2rem;
            margin: 0;
            color: #ff6b6b;
        }

        .container p {
            font-size: 1.2rem;
            margin: 20px 0;
        }

        .container a {
            display: inline-block;
            margin-top: 20px;
            padding: 10px 20px;
            background-color: #ff6b6b;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s ease;
        }

        .container a:hover {
            background-color: #ff3b3b;
        }

        @media (max-width: 768px) {
            .container h1 {
                font-size: 6rem;
            }

            .container h2 {
                font-size: 1.5rem;
            }
        }

