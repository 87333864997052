.newformuser {
  width: 85%;
  display: flex;
  margin: auto;
  margin-top: 4rem;
  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;

      display: flex;
      flex-direction: row;
      justify-items: center;
      justify-content: space-between;
      text-align: center;
      position: relative;

      h1 {
        color: rgb(117, 114, 114);
        font-size: 20px;
        text-align: center;
        margin-top: 1rem;
      }

      // .left {
      //   flex: 1;
      //   text-align: center;

      //   img {
      //     width: 100px;
      //     height: 100px;
      //     border-radius: 50%;
      //     object-fit: cover;
      //   }
      // }

      .right {
        flex: 1;
        .card_section {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          background-color: #eee;
          padding: 1rem;
          gap: 10px;
          .card_button {
            // height: 40px;
            width: 248px;
            // background-color:#f2f3f3;
            background-color: #fffdf6;
            // margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            box-shadow: 0px 3px 3px 0px;
            border-radius: 10px;
            cursor: pointer;
          }
        }
        form {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;

          background-color: rgb(240 240 240);
          padding: 1rem;
          // .all_invoice{
          //   width: 100%;
          //   display: flex;
          //   flex-direction: column;
          //   align-items: center;
          //   // flex-wrap: wrap;
          //   justify-content: space-between;
          //   gap: 1rem;
          // }
          .form_input {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1rem;
          }
          .form_input_invoice {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
          }
          .form_button {
            margin-top: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            // justify-content: center;
            justify-content: center;
            left: 0;
            bottom: 0;
          }
          .form_button_invoice {
            margin-top: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            left: 0;
            bottom: 0;
          }
          .div_button {
            cursor: pointer;
          }
          .formInput {
            width: 31%;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            text-align: left;

            label {
              display: flex;
              width: 24%;
              align-items: center;
              justify-content: flex-start;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 75%;
              padding: 0.5rem;
              border: none;
              font-size: 1rem;
              border-bottom: 1px solid gray;
              border-radius: 0.4rem;
              &[type="date"],
              &[type="month"] {
                position: relative;
              }
              &[type="date"]::-webkit-calendar-picker-indicator,
              &[type="month"]::-webkit-calendar-picker-indicator {
                position: absolute;
                width: 100%;
                height: 100%;
                color: transparent;
                background: transparent;
              }

              &:focus,
              &:active {
                outline: none;
                width: 75%;
                box-shadow: 0.5px 1.5px 4.5px #8a8787;
                padding: 0.5rem;
                // border-bottom: 3px solid rgb(94, 72, 72);
              }
            }
            .input_readOnly {
              width: 75%;
              padding: 0.5rem;
              border: none;
              font-size: 1rem;
              border-bottom: 1px solid gray;
              border-radius: 0.4rem;
              background-color: #e6e6e6;
              cursor: not-allowed;
            }
          }

          .css-1s2u09g-control {
            border: none;
            outline: 0;
            border-style: none;
            border-bottom: 1px solid gray;
          }
          .css-1pahdxg-control {
            border: none;
            outline: 0;
            border-style: none;
            border-width: 0px;
            border-color: transparent;
            border-bottom: 3px solid rgb(94, 72, 72);
            box-shadow: none;
          }
          .search_dropdown {
            padding: 0 1rem;
          }
          .css-2613qy-menu {
            width: 90% !important;
            background-color: red !important;
          }
        }
      }
    }
    button {
      width: 18rem;
      padding: 10px;
      border: none;
      background-color: teal;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin: 10px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.newform {
  width: 85%;
  display: flex;
  margin-left: auto;
  margin-top: 4rem;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;

      display: flex;
      flex-direction: row;
      justify-items: center;
      justify-content: space-between;
      text-align: center;
      position: relative;

      h1 {
        color: rgb(117, 114, 114);
        font-size: 20px;
        text-align: center;
        margin-top: 1rem;
      }

      .right {
        flex: 1;
        .card_section {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          background-color: #eee;
          padding: 1rem;
          gap: 10px;
          .card_button {
            // height: 40px;
            width: 248px;
            // background-color:#f2f3f3;
            background-color: #fffdf6;
            // margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            box-shadow: 0px 3px 3px 0px;
            border-radius: 10px;
            cursor: pointer;
          }
        }
        form {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;

          background-color: rgb(240 240 240);
          padding: 1rem;

          .form_input {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1rem;
          }
          .centerInput {
            justify-content: start;
          }
          .flexContentNone {
            justify-content: flex-start;
          }
          .form_input_invoice {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
          }
          .form_button {
            margin-top: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            // justify-content: center;
            justify-content: flex-start;
            left: 0;
            bottom: 0;
          }
          .centerButton {
            justify-content: center;
          }
          .form_button_invoice {
            margin-top: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            left: 0;
            bottom: 0;
          }
          .div_button {
            cursor: pointer;
          }
          .formInput {
            width: 31%;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            text-align: left;

            label {
              display: flex;
              width: 24%;
              align-items: center;
              justify-content: flex-start;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 75%;
              padding: 0.5rem;
              border: none;
              font-size: 1rem;
              border-bottom: 1px solid gray;
              border-radius: 0.4rem;
              &[type="date"],
              &[type="month"] {
                position: relative;
              }
              &[type="date"]::-webkit-calendar-picker-indicator,
              &[type="month"]::-webkit-calendar-picker-indicator {
                position: absolute;
                width: 100%;
                height: 100%;
                color: transparent;
                background: transparent;
              }

              &:focus,
              &:active {
                outline: none;
                width: 75%;
                box-shadow: 0.5px 1.5px 4.5px #8a8787;
                padding: 0.5rem;
              }
            }
            .input_readOnly {
              width: 75%;
              padding: 0.5rem;
              border: none;
              font-size: 1rem;
              border-bottom: 1px solid gray;
              border-radius: 0.4rem;
              background-color: #e6e6e6;
              cursor: not-allowed;
            }
          }

          .css-1s2u09g-control {
            border: none;
            outline: 0;
            border-style: none;
            border-bottom: 1px solid gray;
          }
          .css-1pahdxg-control {
            border: none;
            outline: 0;
            border-style: none;
            border-width: 0px;
            border-color: transparent;
            border-bottom: 3px solid rgb(94, 72, 72);
            box-shadow: none;
          }
          .search_dropdown {
            padding: 0 1rem;
          }
          .css-2613qy-menu {
            width: 90% !important;
            background-color: red !important;
          }
        }
      }
    }
  }

  button {
    width: 18rem;
    padding: 10px;
    border: none;
    background-color: teal;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button_table {
    width: 6rem;
    padding: 10px;
    border: none;
    background-color: teal;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttonDisable {
    width: 18rem;
    padding: 10px;
    border: none;
    background-color: rgb(110, 133, 133);
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
  .icon {
    margin-right: 1rem;
  }
  .button_group_show {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .button_group_hide {
    display: none;
    flex-direction: row;
    justify-content: space-between;
  }
}
.Table {
  box-shadow: 2px 5px 10px 1px rgba(201, 201, 201, 0.47);
  font-family: "Tiro Bangla", serif !important;
  font-style: normal;
}
.css-1q1u3t4-MuiTableRow-root {
  &:not(:last-child) {
    border-bottom: 0 !important;
  }
}
.row_padding {
  padding: 0.9rem !important;
  font-family: inherit !important;
  font-size: 1rem !important;
  text-align: center !important;
}
.css-1ygcj2i-MuiTableCell-root {
  text-align: center !important;
  font-family: inherit !important;
  font-size: 1.3rem !important;
  font-weight: 800;
}
.MuiTableCell-head {
  font-weight: 700 !important;
  font-family: "Tiro Bangla", serif !important;
  font-style: normal;
  font-size: 1.3rem !important;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 0 !important;
  font-family: inherit !important;
  text-align: center !important;
  font-size: 1.2rem !important;
}
.css-1gqug66 {
  padding: 1rem !important;
}

.css-q34dxg {
  font-family: "Tiro Bangla", serif !important;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: center !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.87);
}
.css-1wbz3t9 {
  font-family: "Tiro Bangla", serif !important;
  font-weight: 700;
  font-size: 1.3rem !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: center !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.87);
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  height: 1rem !important;
  width: 1rem !important;
  padding: 1rem 1rem !important;
  border-radius: 50% !important;
  background-color: transparent !important;
  &:hover {
    background-color: rgba(227, 223, 223, 0.9) !important;
  }
  .delete_icon {
    color: var(--color_red);
  }
  .edit_icon {
    color: var(--color_green);
  }
}
.group_button {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.middleDate input[type="month"] {
  position: relative;
}
.middleDate input[type="month"]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  color: transparent;
  background: transparent;
}

.MuiPaginationItem-root {
  height: 2rem !important;
  width: 2rem !important;
  padding: 0 !important;
  border-radius: 50% !important;
}
.showEntites {
  margin: 0;
  padding: 0;
  margin-left: 25px;
}
select {
  border: none;
  width: 60px;
  font-size: 1rem;
}
select:focus,
select:active {
  outline: none;
}
.search_dropdown {
  width: 75% !important;
  padding: 0 !important;
  border: none;
  border-radius: 0;
}

#react-select-3-input,
#react-select-5-input,
#react-select-7-input,
#react-select-11-input,
#react-select-9-input {
  height: 25px;
}
.MuiTableRow-root,
.MuiTableCell-root {
  border: 1px solid #eee;
}
.SelectInput {
  width: 100%;
}
