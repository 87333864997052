.navbar {
   flex: 0 0 86%;
    height: 70px;
    border-bottom: 0.5px solid #e7e4e4;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #555;
    position:fixed;
    top: 0;
    left: 15%;
    width: 85%;
    z-index:10;
    margin-left: auto;
    background-color: #fff;
    


  
    .wrapper {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
  
      .search {
        width: 30%;
        position: relative;
        .searchBar{
          width: 100%;
          display: flex;
          align-items: center;
          border: 0.5px solid lightgray;
          justify-content: space-between;
        }
        input {
          border: none;
          outline: none;
          background: transparent;
          width:100%;
          padding: .2rem 1rem;
          font-size: 1.4rem;
          align-items: center;
          &::placeholder {
            font-size: 1.4rem;
          }
        }
        .icon{
          cursor: pointer;
          pointer-events: painted;
          margin-right: .5rem;
        }
      }
  
      .items {
        display: flex;
        align-items: center;
        cursor: pointer;
  
        .item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          position: relative;
  
          .icon {
            font-size: 20px;
          }
  
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
  
          .counter {
            width: 15px;
            height: 15px;
            background-color: red;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -5px;
            right: -5px;
          }
        }
      }
    }
  }