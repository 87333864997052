/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"); */
// @import url("https://fonts.googleapis.com/css2?family=Tiro+Bangla:ital@0;1&display=swap");
// * {
//   box-sizing: border-box;
// }
// :root {
//   --td-width-view: 150px;
//   --td-width-print: 100px;
//   --print-font-p-size: 11px;
//   --print-font-t-size: 14px;
//   --view-font-p-size: 14px;
//   --view-font-t-size: 21px;
// }

// body {
//   font-family: "Tiro Bangla", "Times New Roman";
//   /* display: contents; */
//   justify-content: center;
//   margin: 0;
//   padding: 0;
//   // background-color: #d8d6d6;
// }
// .print_grid {
//   display: grid;
//   grid-template-columns: auto 14rem;
//   width: 85%;
//   // margin-left:auto;
//   // margin-top: 5rem;
//   position: relative;
// }
.center_invoice-submeter {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  background-color: "#e4e4e4";
  width: 100%;
  width: fit-content;
}
.print_option_submeter {
  position: relative;
}
.fixed_button_submeter {
  position: fixed;
  top: 50%;
  right: 1rem;
}
.css-1ygcj2i-MuiTableCell-root {
  padding: 8px 4px;
}
/* MAIN SECTION CSS START */
#main-section-submeter {
  // width: 1160px;
  min-width: 7in;
  // width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 10px;
  margin: auto;
  background-color: white;
}

.power-bill {
  padding: 10px 30px;
  margin: 0 auto;
  width: 500px;
}
// .css-1ygcj2i-MuiTableCell-root{
//   padding: 8px !important;
//   font-size: 12px !important;
//   // line-height: 0;
// }

.css-quj9j6-MuiTable-root {
  // width: 100% !important;
  min-width: none !important;
}
.top-section1 {
  text-align: center;
  padding: 0;
  margin: 0;
}

.reportButton > button {
  width: 11rem;
}
@media print {
  #main-section-submeter {
    width: 100%;
    height: 595px;
    padding: 4px 8px;
    // background-color: white;
  }
  // #main-section-submeter {
  //   width: 100%;
  //   // height: 10px;
  //   background-image: url('cinnomul-logo.png');
  //   background-position: center center;
  //   background-repeat: no-repeat;
  // }
  .top-section1 * {
    padding: 0 !important;
    margin: 0 !important;
  }
  .tableCell {
    font-family: "Tiro Bangla", serif !important;
    font-weight: 400 !important;
    font-size: 1.2rem !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgb(224, 224, 224);
    text-align: center !important;
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .css-quj9j6-MuiTable-root {
    // width: 100% !important;
    min-width: none !important;
  }
  .css-1ygcj2i-MuiTableCell-root {
    padding: 6px !important;
    font-size: 10px !important;
  }

  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
    // background-color: transparent !important;
    background-image: url("cinnomul-logo.png");
    width: 100%;
    // height: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: repeat;
    // height: 10in;
  }
}
// td {
//   padding-left: 4px;
// }
/* MAIN SECTION CSS END */
/* TOP SECTION CSS START */
// #top-section {
//   margin-top: 20px;
//   text-align: center;
// }
// #top-section > p {
//   margin: 0;
//   padding: 0;
//   font-size: 12px;
// }
// #top-section > h4 {
//   padding: 0;
//   margin: 0;
// }
/* TOP SECTION CSS END */
/* CUSTOMER SECTION CSS START */

// .customer-info-top-table,
// .customer-info-center-table,
// .customer-info-bottom-table {
//   border-collapse: collapse;
// }
// .info {
//   font-size: 11px;
// }
/* .customer-info-table , tr{
    border: 2px solid red;
} */
/* CUSTOMER SECTION CSS END */
/* OFFICE COPY SECTION CSS START */
// .office-copy-table,
// tr,
// td {
//   border: 1px solid black;
//   border-collapse: collapse;
//   font-size: 11px;
// }

/* OFFICE COPY SECTION CSS END */

// @media print {
//     html, body {
//       height: 100vh; /* Use 100% here to support printing more than a single page*/
//       margin: 0 !important;
//       padding: 0 !important;
//       overflow: hidden;
//     }
//   }
