.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .table {
    background-color: #111;

    .tableCell {
      color: gray;
    }
  }

  .navbar {
    // color: #999;
    border-color: #333;
    background-color: #111;
    color: rgb(156, 156, 156);

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #111;
    border-color: #333;

    .top {
      .logo {
        color: #999;
      }
    }
    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon,
        span {
          color: #999;
        }
      }
    }
  }

  .datatable {
    .datagrid {
      color: gray;
      border: none;

      .viewButton,
      .deleteButton,
      .cellWithStatus {
        color: gray;
        border: none;
      }
    }
  }
  form {
    background-color: transparent !important;
    & .search_dropdown {
      border: 1px solid #696969;
      border-radius: 0.5rem;
      & .css-qc6sy-singleValue {
        color: #aeaeae;
      }
    }
    & input {
      background-color: transparent !important;
      border: 1px solid !important;
      color: #aeaeae !important;
    }
  }

  .sidebar-item.plain {
    color: #999;
    text-decoration: none;
  }
  .css-1s2u09g-control {
    background-color: #111;
    color: #ccc !important;
    &:focus {
      background-color: #111;
      color: #ccc !important;
    }
  }

  .css-319lph-ValueContainer {
    background-color: #111;
    color: #ccc !important;
    &:active,
    &:visited,
    &:active &:focus {
      background-color: #111;
      color: #ccc !important;
    }
  }
  .css-1pahdxg-control {
    border-color: #333;
    background-color: #111;
    color: rgb(156, 156, 156);
  }
  .css-6j8wv5-Input {
    color: #ccc !important;
  }
}
