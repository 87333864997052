.loginMainContainer {
  // display: inherit;
  width: 100%;
  height: 100vh;
  // background: url('billsystem/src/components/login/background.png');
  // background: url('https://iili.io/2dOhBXj.png');
  background: url("https://cdn.pixabay.com/photo/2019/12/01/18/43/fog-4666170_1280.jpg");
  position: relative;
  background-size: cover;
}

.loginContainer {
  width: 500px;
  height: 450px;
  padding: 20px;
  top: 3%;
  // left: 35%;
  margin: auto auto;
  background-color: rgb(57 62 62 / 65%);
  color: white;
  position: relative;
  box-shadow: 8px 2px 10px 0px rgba(61, 61, 62, 0.2);
}
.loginItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px 0;
}
.circleLogo {
  width: 100px;
  height: 110px;
  border-radius: 50%;
  // border: 1px solid red;
  overflow: hidden;
  // background-color: white;
}
.loginInputContainer {
  width: 90%;
  margin: auto;

  padding: 16px 0;
}
.loginInput {
  width: 100%;
  padding: 8px;
  gap: 10px 0;
  font-size: 1.1rem;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  outline: none;
}
.loginButton {
  float: left;
  width: 100%;
  height: 40px;
  margin-top: 30px;
  /* background-color: #7c97e9; */
  /* color: white; */
  font-size: 1.15rem;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .loginContainer {
    width: 85%;
    top: 0;
  }
}
// .loginContainer{
//     display: block;
//     box-sizing: border-box;
//     width: 100%;
//     height: 100%;
// }
// .customer-input{
//     display: flex;
//     // align-items: center;
//     justify-content: center;
//     // position: relative;
//     // height: 100vh ;
//     // width: 400px;
// }
// .card{
//     // height: 30rem;
//     // width: 40rem;
//     background-color: #f5f7f7;
//     border-radius: 1rem;
//     box-shadow: 1px 13px 10px 0px rgba(61, 61, 62, 0.2);
//     display: flex;
//     padding: 2rem;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;

// }
// .circleLogo{
//     width: 100px;
//     height: 100px;
//     border-radius: 50%;
//     border: 1px solid red;
//     overflow: hidden;
//     // background-color: white;
// }
// .userInput {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-content: center;
//     align-items: baseline;
//     gap: 20px;

// }
// .userInput input{
//     width: 250px;
//     border-radius: 10px;

// }
// .css-bz5ng3-MuiInputBase-root-MuiInput-root{
//     background-color: white;
//     // padding: 4px;
// }
// .css-bz5ng3-MuiInputBase-root-MuiInput-root::before{
//     border: none;
// }
// // .title{
// //     margin-bottom: 3rem !important;
// // }

// // .css-1okj3ks-MuiStack-root{
// //     width: 20rem;
// // }
// // .MuiInput-root{
// //     margin-bottom: 3rem !important;
// // }
