.home {
    display: flex;
    position: relative;
  
    .homeContainer {
      flex: 6;
      max-width: 85%;
      margin-left: auto;
      position: relative;


      .widgets,
      .charts {
        margin-top:4rem;
        display: flex;
        padding: 20px;
        gap: 20px;
      }
  
      .charts {
        padding: 5px 20px;
      }
  
      .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        margin-top: 4rem;
        padding: 20px;
        // margin: 20px;
        // display: flex;
        // max-width: 85%;
        // margin-left: auto;
  
        .listTitle {
          font-weight: 500;
          color: gray;
          margin-bottom: 15px;
        }
      }
    }
  }