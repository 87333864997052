.home {
  display: flex;
  position: relative;

  .homeContainerUser {
    flex: 6;
    max-width: 85%;
    margin: auto;
    position: relative;
    @media only screen and (max-width: 960px) {
      min-width: 95%;
      max-width: 100%;
    }
    // .infocard {
    //   height: 400px;
    //   width: 60%;
    //   background-color: rgb(203, 198, 198);
    //   margin: auto;
    //   border-radius: 10px;
    // }
    .widgets,
    .charts {
      margin-top: 4rem;
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      margin-top: 4rem;
      padding: 20px;
      background-color: rgb(195, 195, 195);
      @media only screen and (max-width: 960px) {
        margin-top: 1rem;
        padding: 5px;

        .css-vzjw0z-MuiTableCell-root {
          padding: 6px;
          line-height: normal;
          font-size: 100%;
        }
        .css-1ygcj2i-MuiTableCell-root {
          padding: 10px 4px;

          line-height: 100% !important;
        }
        .user_tableCell {
          font-size: 12px !important;
        }
      }
      // margin: 20px;
      // display: flex;
      // max-width: 85%;
      // margin-left: auto;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}
