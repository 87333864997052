@import url("https://fonts.googleapis.com/css2?family=Tiro+Bangla&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tiro+Bangla:ital@0;1&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color_red: #ba2014;
  --color_green: #2363b0;
}

body {
  font-family: "Tiro Bangla", serif !important;
  font-style: normal;
  font-size: 1em !important;
}

.newformuser {
  width: 85%;
  display: flex;
  margin: auto;
  margin-top: 4rem;
}
.newformuser .newContainer {
  flex: 6;
}
.newformuser .newContainer .top,
.newformuser .newContainer .bottom {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-between;
  text-align: center;
  position: relative;
}
.newformuser .newContainer .top h1,
.newformuser .newContainer .bottom h1 {
  color: rgb(117, 114, 114);
  font-size: 20px;
  text-align: center;
  margin-top: 1rem;
}
.newformuser .newContainer .top .right,
.newformuser .newContainer .bottom .right {
  flex: 1;
}
.newformuser .newContainer .top .right .card_section,
.newformuser .newContainer .bottom .right .card_section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #eee;
  padding: 1rem;
  gap: 10px;
}
.newformuser .newContainer .top .right .card_section .card_button,
.newformuser .newContainer .bottom .right .card_section .card_button {
  width: 248px;
  background-color: #fffdf6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0px 3px 3px 0px;
  border-radius: 10px;
  cursor: pointer;
}
.newformuser .newContainer .top .right form,
.newformuser .newContainer .bottom .right form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: rgb(240, 240, 240);
  padding: 1rem;
}
.newformuser .newContainer .top .right form .form_input,
.newformuser .newContainer .bottom .right form .form_input {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}
.newformuser .newContainer .top .right form .form_input_invoice,
.newformuser .newContainer .bottom .right form .form_input_invoice {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.newformuser .newContainer .top .right form .form_button,
.newformuser .newContainer .bottom .right form .form_button {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
}
.newformuser .newContainer .top .right form .form_button_invoice,
.newformuser .newContainer .bottom .right form .form_button_invoice {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
}
.newformuser .newContainer .top .right form .div_button,
.newformuser .newContainer .bottom .right form .div_button {
  cursor: pointer;
}
.newformuser .newContainer .top .right form .formInput,
.newformuser .newContainer .bottom .right form .formInput {
  width: 31%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  text-align: left;
}
.newformuser .newContainer .top .right form .formInput label,
.newformuser .newContainer .bottom .right form .formInput label {
  display: flex;
  width: 24%;
  align-items: center;
  justify-content: flex-start;
}
.newformuser .newContainer .top .right form .formInput label .icon,
.newformuser .newContainer .bottom .right form .formInput label .icon {
  cursor: pointer;
}
.newformuser .newContainer .top .right form .formInput input,
.newformuser .newContainer .bottom .right form .formInput input {
  width: 75%;
  padding: 0.5rem;
  border: none;
  font-size: 1rem;
  border-bottom: 1px solid gray;
  border-radius: 0.4rem;
}
.newformuser .newContainer .top .right form .formInput input[type=date], .newformuser .newContainer .top .right form .formInput input[type=month],
.newformuser .newContainer .bottom .right form .formInput input[type=date],
.newformuser .newContainer .bottom .right form .formInput input[type=month] {
  position: relative;
}
.newformuser .newContainer .top .right form .formInput input[type=date]::-webkit-calendar-picker-indicator, .newformuser .newContainer .top .right form .formInput input[type=month]::-webkit-calendar-picker-indicator,
.newformuser .newContainer .bottom .right form .formInput input[type=date]::-webkit-calendar-picker-indicator,
.newformuser .newContainer .bottom .right form .formInput input[type=month]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  color: transparent;
  background: transparent;
}
.newformuser .newContainer .top .right form .formInput input:focus, .newformuser .newContainer .top .right form .formInput input:active,
.newformuser .newContainer .bottom .right form .formInput input:focus,
.newformuser .newContainer .bottom .right form .formInput input:active {
  outline: none;
  width: 75%;
  box-shadow: 0.5px 1.5px 4.5px #8a8787;
  padding: 0.5rem;
}
.newformuser .newContainer .top .right form .formInput .input_readOnly,
.newformuser .newContainer .bottom .right form .formInput .input_readOnly {
  width: 75%;
  padding: 0.5rem;
  border: none;
  font-size: 1rem;
  border-bottom: 1px solid gray;
  border-radius: 0.4rem;
  background-color: #e6e6e6;
  cursor: not-allowed;
}
.newformuser .newContainer .top .right form .css-1s2u09g-control,
.newformuser .newContainer .bottom .right form .css-1s2u09g-control {
  border: none;
  outline: 0;
  border-style: none;
  border-bottom: 1px solid gray;
}
.newformuser .newContainer .top .right form .css-1pahdxg-control,
.newformuser .newContainer .bottom .right form .css-1pahdxg-control {
  border: none;
  outline: 0;
  border-style: none;
  border-width: 0px;
  border-color: transparent;
  border-bottom: 3px solid rgb(94, 72, 72);
  box-shadow: none;
}
.newformuser .newContainer .top .right form .search_dropdown,
.newformuser .newContainer .bottom .right form .search_dropdown {
  padding: 0 1rem;
}
.newformuser .newContainer .top .right form .css-2613qy-menu,
.newformuser .newContainer .bottom .right form .css-2613qy-menu {
  width: 90% !important;
  background-color: red !important;
}
.newformuser .newContainer button {
  width: 18rem;
  padding: 10px;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newform {
  width: 85%;
  display: flex;
  margin-left: auto;
  margin-top: 4rem;
}
.newform .newContainer {
  flex: 6;
}
.newform .newContainer .top,
.newform .newContainer .bottom {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-between;
  text-align: center;
  position: relative;
}
.newform .newContainer .top h1,
.newform .newContainer .bottom h1 {
  color: rgb(117, 114, 114);
  font-size: 20px;
  text-align: center;
  margin-top: 1rem;
}
.newform .newContainer .top .right,
.newform .newContainer .bottom .right {
  flex: 1;
}
.newform .newContainer .top .right .card_section,
.newform .newContainer .bottom .right .card_section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #eee;
  padding: 1rem;
  gap: 10px;
}
.newform .newContainer .top .right .card_section .card_button,
.newform .newContainer .bottom .right .card_section .card_button {
  width: 248px;
  background-color: #fffdf6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0px 3px 3px 0px;
  border-radius: 10px;
  cursor: pointer;
}
.newform .newContainer .top .right form,
.newform .newContainer .bottom .right form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: rgb(240, 240, 240);
  padding: 1rem;
}
.newform .newContainer .top .right form .form_input,
.newform .newContainer .bottom .right form .form_input {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}
.newform .newContainer .top .right form .centerInput,
.newform .newContainer .bottom .right form .centerInput {
  justify-content: start;
}
.newform .newContainer .top .right form .flexContentNone,
.newform .newContainer .bottom .right form .flexContentNone {
  justify-content: flex-start;
}
.newform .newContainer .top .right form .form_input_invoice,
.newform .newContainer .bottom .right form .form_input_invoice {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.newform .newContainer .top .right form .form_button,
.newform .newContainer .bottom .right form .form_button {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  left: 0;
  bottom: 0;
}
.newform .newContainer .top .right form .centerButton,
.newform .newContainer .bottom .right form .centerButton {
  justify-content: center;
}
.newform .newContainer .top .right form .form_button_invoice,
.newform .newContainer .bottom .right form .form_button_invoice {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
}
.newform .newContainer .top .right form .div_button,
.newform .newContainer .bottom .right form .div_button {
  cursor: pointer;
}
.newform .newContainer .top .right form .formInput,
.newform .newContainer .bottom .right form .formInput {
  width: 31%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  text-align: left;
}
.newform .newContainer .top .right form .formInput label,
.newform .newContainer .bottom .right form .formInput label {
  display: flex;
  width: 24%;
  align-items: center;
  justify-content: flex-start;
}
.newform .newContainer .top .right form .formInput label .icon,
.newform .newContainer .bottom .right form .formInput label .icon {
  cursor: pointer;
}
.newform .newContainer .top .right form .formInput input,
.newform .newContainer .bottom .right form .formInput input {
  width: 75%;
  padding: 0.5rem;
  border: none;
  font-size: 1rem;
  border-bottom: 1px solid gray;
  border-radius: 0.4rem;
}
.newform .newContainer .top .right form .formInput input[type=date], .newform .newContainer .top .right form .formInput input[type=month],
.newform .newContainer .bottom .right form .formInput input[type=date],
.newform .newContainer .bottom .right form .formInput input[type=month] {
  position: relative;
}
.newform .newContainer .top .right form .formInput input[type=date]::-webkit-calendar-picker-indicator, .newform .newContainer .top .right form .formInput input[type=month]::-webkit-calendar-picker-indicator,
.newform .newContainer .bottom .right form .formInput input[type=date]::-webkit-calendar-picker-indicator,
.newform .newContainer .bottom .right form .formInput input[type=month]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  color: transparent;
  background: transparent;
}
.newform .newContainer .top .right form .formInput input:focus, .newform .newContainer .top .right form .formInput input:active,
.newform .newContainer .bottom .right form .formInput input:focus,
.newform .newContainer .bottom .right form .formInput input:active {
  outline: none;
  width: 75%;
  box-shadow: 0.5px 1.5px 4.5px #8a8787;
  padding: 0.5rem;
}
.newform .newContainer .top .right form .formInput .input_readOnly,
.newform .newContainer .bottom .right form .formInput .input_readOnly {
  width: 75%;
  padding: 0.5rem;
  border: none;
  font-size: 1rem;
  border-bottom: 1px solid gray;
  border-radius: 0.4rem;
  background-color: #e6e6e6;
  cursor: not-allowed;
}
.newform .newContainer .top .right form .css-1s2u09g-control,
.newform .newContainer .bottom .right form .css-1s2u09g-control {
  border: none;
  outline: 0;
  border-style: none;
  border-bottom: 1px solid gray;
}
.newform .newContainer .top .right form .css-1pahdxg-control,
.newform .newContainer .bottom .right form .css-1pahdxg-control {
  border: none;
  outline: 0;
  border-style: none;
  border-width: 0px;
  border-color: transparent;
  border-bottom: 3px solid rgb(94, 72, 72);
  box-shadow: none;
}
.newform .newContainer .top .right form .search_dropdown,
.newform .newContainer .bottom .right form .search_dropdown {
  padding: 0 1rem;
}
.newform .newContainer .top .right form .css-2613qy-menu,
.newform .newContainer .bottom .right form .css-2613qy-menu {
  width: 90% !important;
  background-color: red !important;
}
.newform button {
  width: 18rem;
  padding: 10px;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newform .button_table {
  width: 6rem;
  padding: 10px;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newform .buttonDisable {
  width: 18rem;
  padding: 10px;
  border: none;
  background-color: rgb(110, 133, 133);
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.newform .icon {
  margin-right: 1rem;
}
.newform .button_group_show {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.newform .button_group_hide {
  display: none;
  flex-direction: row;
  justify-content: space-between;
}

.Table {
  box-shadow: 2px 5px 10px 1px rgba(201, 201, 201, 0.47);
  font-family: "Tiro Bangla", serif !important;
  font-style: normal;
}

.css-1q1u3t4-MuiTableRow-root:not(:last-child) {
  border-bottom: 0 !important;
}

.row_padding {
  padding: 0.9rem !important;
  font-family: inherit !important;
  font-size: 1rem !important;
  text-align: center !important;
}

.css-1ygcj2i-MuiTableCell-root {
  text-align: center !important;
  font-family: inherit !important;
  font-size: 1.3rem !important;
  font-weight: 800;
}

.MuiTableCell-head {
  font-weight: 700 !important;
  font-family: "Tiro Bangla", serif !important;
  font-style: normal;
  font-size: 1.3rem !important;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 0 !important;
  font-family: inherit !important;
  text-align: center !important;
  font-size: 1.2rem !important;
}

.css-1gqug66 {
  padding: 1rem !important;
}

.css-q34dxg {
  font-family: "Tiro Bangla", serif !important;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: center !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.87);
}

.css-1wbz3t9 {
  font-family: "Tiro Bangla", serif !important;
  font-weight: 700;
  font-size: 1.3rem !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: center !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.87);
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  height: 1rem !important;
  width: 1rem !important;
  padding: 1rem 1rem !important;
  border-radius: 50% !important;
  background-color: transparent !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: rgba(227, 223, 223, 0.9) !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root .delete_icon {
  color: var(--color_red);
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root .edit_icon {
  color: var(--color_green);
}

.group_button {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.middleDate input[type=month] {
  position: relative;
}

.middleDate input[type=month]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  color: transparent;
  background: transparent;
}

.MuiPaginationItem-root {
  height: 2rem !important;
  width: 2rem !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.showEntites {
  margin: 0;
  padding: 0;
  margin-left: 25px;
}

select {
  border: none;
  width: 60px;
  font-size: 1rem;
}

select:focus,
select:active {
  outline: none;
}

.search_dropdown {
  width: 75% !important;
  padding: 0 !important;
  border: none;
  border-radius: 0;
}

#react-select-3-input,
#react-select-5-input,
#react-select-7-input,
#react-select-11-input,
#react-select-9-input {
  height: 25px;
}

.MuiTableRow-root,
.MuiTableCell-root {
  border: 1px solid #eee;
}

.SelectInput {
  width: 100%;
}

.sidebar {
  flex: 0 0 15%;
  height: 100%;
  width: 15%;
  overflow: auto;
}
.sidebar .top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar .top .logo {
  font-size: 20px;
  font-weight: bold;
  color: #6439ff;
}

.sidebar-item {
  padding: 0.75rem 1rem;
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
}

.sidebar-item:hover {
  background-color: rgba(49, 59, 195, 0.1);
}

.sidebar-title {
  pointer-events: visible;
  cursor: pointer;
  display: flex;
  font-size: 1.3em;
  padding: 1rem 0;
  justify-content: space-between;
}

.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}

.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  font-size: 1.2em;
  color: #000;
  text-decoration: none;
}

.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}

.chart {
  flex: 4;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
}
.chart .title {
  margin-bottom: 20px;
}
.chart .chartGrid {
  stroke: rgb(228, 225, 225);
}

.datatable {
  flex: 0 0 83%;
  height: 600px;
  padding: 1rem;
  position: relative;
  margin-top: 4rem;
  width: 83%;
  margin-left: auto;
}
.datatable .datatableTitle {
  width: 100%;
  font-size: 24px;
  color: gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.datatable .datatableTitle .link {
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.datatable .cellWithImg {
  display: flex;
  align-items: center;
}
.datatable .cellWithImg .cellImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 20px;
}
.datatable .cellWithStatus {
  padding: 5px;
  border-radius: 5px;
}
.datatable .cellWithStatus.active {
  background-color: rgba(0, 128, 0, 0.05);
  color: green;
}
.datatable .cellWithStatus.pending {
  background-color: rgba(255, 217, 0, 0.05);
  color: goldenrod;
}
.datatable .cellWithStatus.passive {
  background-color: rgba(255, 0, 0, 0.05);
  color: crimson;
}
.datatable .cellAction {
  display: flex;
  align-items: center;
  gap: 15px;
}
.datatable .cellAction .viewButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: darkblue;
  border: 1px dotted rgba(0, 0, 139, 0.596);
  cursor: pointer;
}
.datatable .cellAction .deleteButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: crimson;
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
.datatable .MuiDataGrid-root,
.datatable .css-3ihp42-MuiDataGrid-root {
  flex: 0 0 100% !important;
  display: flex;
  flex-direction: row;
}

.featured {
  flex: 2;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
}
.featured .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
}
.featured .top .title {
  font-size: 16px;
  font-weight: 500;
}
.featured .bottom {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.featured .bottom .featuredChart {
  width: 100px;
  height: 100px;
}
.featured .bottom .title {
  font-weight: 500;
  color: gray;
}
.featured .bottom .amount {
  font-size: 30px;
}
.featured .bottom .desc {
  font-weight: 300;
  font-size: 12px;
  color: gray;
  text-align: center;
}
.featured .bottom .summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.featured .bottom .summary .item {
  text-align: center;
}
.featured .bottom .summary .item .itemTitle {
  font-size: 14px;
  color: gray;
}
.featured .bottom .summary .item .itemResult {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}
.featured .bottom .summary .item .itemResult.positive {
  color: green;
}
.featured .bottom .summary .item .itemResult.negative {
  color: red;
}

.loginMainContainer {
  width: 100%;
  height: 100vh;
  background: url("https://cdn.pixabay.com/photo/2019/12/01/18/43/fog-4666170_1280.jpg");
  position: relative;
  background-size: cover;
}

.loginContainer {
  width: 500px;
  height: 450px;
  padding: 20px;
  top: 3%;
  margin: auto auto;
  background-color: rgba(57, 62, 62, 0.65);
  color: white;
  position: relative;
  box-shadow: 8px 2px 10px 0px rgba(61, 61, 62, 0.2);
}

.loginItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px 0;
}

.circleLogo {
  width: 100px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.loginInputContainer {
  width: 90%;
  margin: auto;
  padding: 16px 0;
}

.loginInput {
  width: 100%;
  padding: 8px;
  gap: 10px 0;
  font-size: 1.1rem;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  outline: none;
}

.loginButton {
  float: left;
  width: 100%;
  height: 40px;
  margin-top: 30px;
  /* background-color: #7c97e9; */
  /* color: white; */
  font-size: 1.15rem;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .loginContainer {
    width: 85%;
    top: 0;
  }
}
.navbar {
  flex: 0 0 86%;
  height: 70px;
  border-bottom: 0.5px solid #e7e4e4;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #555;
  position: fixed;
  top: 0;
  left: 15%;
  width: 85%;
  z-index: 10;
  margin-left: auto;
  background-color: #fff;
}
.navbar .wrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.navbar .wrapper .search {
  width: 30%;
  position: relative;
}
.navbar .wrapper .search .searchBar {
  width: 100%;
  display: flex;
  align-items: center;
  border: 0.5px solid lightgray;
  justify-content: space-between;
}
.navbar .wrapper .search input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 0.2rem 1rem;
  font-size: 1.4rem;
  align-items: center;
}
.navbar .wrapper .search input::-moz-placeholder {
  font-size: 1.4rem;
}
.navbar .wrapper .search input::placeholder {
  font-size: 1.4rem;
}
.navbar .wrapper .search .icon {
  cursor: pointer;
  pointer-events: painted;
  margin-right: 0.5rem;
}
.navbar .wrapper .items {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navbar .wrapper .items .item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
}
.navbar .wrapper .items .item .icon {
  font-size: 20px;
}
.navbar .wrapper .items .item .avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.navbar .wrapper .items .item .counter {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: -5px;
  right: -5px;
}

.sidebar {
  position: fixed;
  flex: 0 0 20%;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: white;
  max-width: 20%;
  top: 0;
  left: 0;
}
.sidebar .top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar .top .logo {
  font-size: 20px;
  font-weight: bold;
  color: #6439ff;
}
.sidebar hr {
  height: 0;
  border: 0.5px solid rgb(230, 227, 227);
}
.sidebar .center {
  padding-left: 10px;
}
.sidebar .center ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar .center ul .title {
  font-size: 10px;
  font-weight: bold;
  color: #999;
  margin-top: 15px;
  margin-bottom: 5px;
}
.sidebar .center ul li {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.sidebar .center ul li:hover {
  background-color: #ece8ff;
}
.sidebar .center ul li .icon {
  font-size: 2rem;
  color: #888;
}
.sidebar .center ul li .sidebaritem {
  flex-direction: row;
  display: flex;
  background-color: red;
  justify-content: space-between;
}
.sidebar .center ul li .sidebaritem:hover {
  background-color: white;
}
.sidebar .center ul li span {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(45, 42, 42);
  margin-left: 10px;
}
.sidebar .bottom {
  display: flex;
  align-items: center;
  margin: 10px;
}
.sidebar .bottom .dark {
  color: w;
}
.sidebar .bottom .colorOption {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #7451f8;
  cursor: pointer;
  margin: 5px;
}
.sidebar .bottom .colorOption:nth-child(1) {
  background-color: whitesmoke;
}
.sidebar .bottom .colorOption:nth-child(2) {
  background-color: #333;
}

.table .cellWrapper {
  display: flex;
  align-items: center;
}
.table .cellWrapper .image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.table .status {
  padding: 5px;
  border-radius: 5px;
}
.table .status.true {
  color: green;
  background-color: rgba(0, 128, 0, 0.151);
}
.table .status.false {
  color: goldenrod;
  background-color: rgba(189, 189, 3, 0.103);
}

.tableCell_actions {
  text-align: center !important;
}

.loginMainContainer {
  width: 100%;
  height: 100vh;
  background: url("https://cdn.pixabay.com/photo/2019/12/01/18/43/fog-4666170_1280.jpg");
  position: relative;
  background-size: cover;
}

.loginContainer {
  width: 500px;
  height: 450px;
  padding: 20px;
  top: 3%;
  margin: auto auto;
  background-color: rgba(57, 62, 62, 0.65);
  color: white;
  position: relative;
  box-shadow: 8px 2px 10px 0px rgba(61, 61, 62, 0.2);
}

.loginItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px 0;
}

.circleLogo {
  width: 100px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.loginInputContainer {
  width: 90%;
  margin: auto;
  padding: 16px 0;
}

.loginInput {
  width: 100%;
  padding: 8px;
  gap: 10px 0;
  font-size: 1.1rem;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  outline: none;
}

.loginButton {
  float: left;
  width: 100%;
  height: 40px;
  margin-top: 30px;
  /* background-color: #7c97e9; */
  /* color: white; */
  font-size: 1.15rem;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .loginContainer {
    width: 85%;
    top: 0;
  }
}
.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100px;
  cursor: pointer;
}
.widget .left,
.widget .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.widget .left .title,
.widget .right .title {
  font-weight: bold;
  font-size: 14px;
  color: rgb(160, 160, 160);
}
.widget .left .counter,
.widget .right .counter {
  font-size: 28px;
  font-weight: 300;
}
.widget .left .link,
.widget .right .link {
  width: -moz-max-content;
  width: max-content;
  font-size: 12px;
  border-bottom: 1px solid gray;
}
.widget .left .percentage,
.widget .right .percentage {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.widget .left .percentage.positive,
.widget .right .percentage.positive {
  color: green;
}
.widget .left .percentage.negative,
.widget .right .percentage.negative {
  color: red;
}
.widget .left .icon,
.widget .right .icon {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}

/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"); */
* {
  box-sizing: border-box;
}

:root {
  --td-width-view: 150px;
  --td-width-print: 100px;
  --print-font-p-size: 11px;
  --print-font-t-size: 14px;
  --view-font-p-size: 14px;
  --view-font-t-size: 21px;
}

body {
  font-family: "Tiro Bangla", "Times New Roman" !important;
  /* display: contents; */
  justify-content: center;
  margin: 0;
  padding: 0;
}

.print_grid {
  display: grid;
  grid-template-columns: auto 14rem;
  width: 85%;
  position: relative;
}

.center_invoice {
  display: flex;
  justify-content: center;
  background-color: #9a9a9a;
  overflow-y: scroll;
}

.print_option {
  position: relative;
}

.fixed_button {
  position: fixed;
  top: 50%;
  right: 6rem;
}

/* MAIN SECTION CSS START */
#main-section {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: white;
}

.power-bill {
  padding: 10px 30px;
  margin: 0 auto;
  /* background-color: black;
    color: white; */
  width: 150px;
}

td {
  padding-left: 4px;
}

/* MAIN SECTION CSS END */
/* TOP SECTION CSS START */
#top-section {
  margin-top: 20px;
  text-align: center;
}

#top-section > p {
  margin: 0;
  padding: 0;
  font-size: 13px;
}

#top-section > h4 {
  padding: 0;
  margin: 0;
}

/* TOP SECTION CSS END */
/* CUSTOMER SECTION CSS START */
.customer-info-top-table,
.customer-info-center-table,
.customer-info-bottom-table {
  border-collapse: collapse;
}

.info {
  font-size: 13px;
}

/* .customer-info-table , tr{
    border: 2px solid red;
} */
/* CUSTOMER SECTION CSS END */
/* OFFICE COPY SECTION CSS START */
.office-copy-table,
tr,
td {
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 13px;
}

/* OFFICE COPY SECTION CSS END */
.print-footer {
  display: none;
}

@media print {
  #main-section {
    width: 100%;
    /* height: 595px; */
    background-image: url("https://i.ibb.co.com/n0RjmDL/nogor.png");
    background-position: center center;
    background-repeat: no-repeat;
    page-break-inside: avoid;
  }
  .center_invoice {
    background-color: white !important;
  }
  .print-footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto; /* Adjust height if needed */
    background-color: rgba(255, 255, 255, 0.894);
    padding: 4px;
    text-align: center;
    page-break-after: always; /* Ensure it's printed on each page */
  }
  .css-1ygcj2i-MuiTableCell-root {
    padding: 8px 4px !important;
    font-size: 10px !important;
  }
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
    background-color: transparent !important;
    width: 100%;
  }
  /* .css-1ygcj2i-MuiTableCell-root{

  } */
  /* .css-quj9j6-MuiTable-root {
    min-width: none !important;
  } */
}
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"); */
.center_invoice-submeter {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  background-color: "#e4e4e4";
  width: 100%;
  width: -moz-fit-content;
  width: fit-content;
}

.print_option_submeter {
  position: relative;
}

.fixed_button_submeter {
  position: fixed;
  top: 50%;
  right: 1rem;
}

.css-1ygcj2i-MuiTableCell-root {
  padding: 8px 4px;
}

/* MAIN SECTION CSS START */
#main-section-submeter {
  min-width: 7in;
  min-height: 100vh;
  height: 100%;
  padding: 10px;
  margin: auto;
  background-color: white;
}

.power-bill {
  padding: 10px 30px;
  margin: 0 auto;
  width: 500px;
}

.css-quj9j6-MuiTable-root {
  min-width: none !important;
}

.top-section1 {
  text-align: center;
  padding: 0;
  margin: 0;
}

.reportButton > button {
  width: 11rem;
}

@media print {
  #main-section-submeter {
    width: 100%;
    height: 595px;
    padding: 4px 8px;
  }
  .top-section1 * {
    padding: 0 !important;
    margin: 0 !important;
  }
  .tableCell {
    font-family: "Tiro Bangla", serif !important;
    font-weight: 400 !important;
    font-size: 1.2rem !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgb(224, 224, 224);
    text-align: center !important;
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.87);
  }
  .css-quj9j6-MuiTable-root {
    min-width: none !important;
  }
  .css-1ygcj2i-MuiTableCell-root {
    padding: 6px !important;
    font-size: 10px !important;
  }
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
    background-image: url("cinnomul-logo.png");
    width: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: repeat;
  }
}
/* MAIN SECTION CSS END */
/* TOP SECTION CSS START */
/* TOP SECTION CSS END */
/* CUSTOMER SECTION CSS START */
/* .customer-info-table , tr{
    border: 2px solid red;
} */
/* CUSTOMER SECTION CSS END */
/* OFFICE COPY SECTION CSS START */
/* OFFICE COPY SECTION CSS END */
.home {
  display: flex;
  position: relative;
}
.home .homeContainer {
  flex: 6;
  max-width: 85%;
  margin-left: auto;
  position: relative;
}
.home .homeContainer .widgets,
.home .homeContainer .charts {
  margin-top: 4rem;
  display: flex;
  padding: 20px;
  gap: 20px;
}
.home .homeContainer .charts {
  padding: 5px 20px;
}
.home .homeContainer .listContainer {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  margin-top: 4rem;
  padding: 20px;
}
.home .homeContainer .listContainer .listTitle {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}

.errorContainer {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background-color: #f3f4f6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: #333;
}

.container {
  max-width: 600px;
  padding: 20px;
}

.container h1 {
  font-size: 8rem;
  margin: 0;
  color: #ff6b6b;
}

.container h2 {
  font-size: 2rem;
  margin: 0;
  color: #ff6b6b;
}

.container p {
  font-size: 1.2rem;
  margin: 20px 0;
}

.container a {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff6b6b;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.container a:hover {
  background-color: #ff3b3b;
}

@media (max-width: 768px) {
  .container h1 {
    font-size: 6rem;
  }
  .container h2 {
    font-size: 1.5rem;
  }
}
.list {
  flex: 1;
  display: flex;
}
.list .listContainer {
  flex: 0 0 100%;
  margin-left: auto;
}

.login {
  color: #000;
}

.new {
  width: 85%;
  display: flex;
  margin-left: auto;
}
.new .newContainer {
  flex: 1;
}
.new .newContainer .top,
.new .newContainer .bottom {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 20px;
  display: flex;
}
.new .newContainer .top h1,
.new .newContainer .bottom h1 {
  color: lightgray;
  font-size: 20px;
}
.new .newContainer .top .left,
.new .newContainer .bottom .left {
  flex: 1;
  text-align: center;
}
.new .newContainer .top .left img,
.new .newContainer .bottom .left img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.new .newContainer .top .right,
.new .newContainer .bottom .right {
  flex: 2;
}
.new .newContainer .top .right form,
.new .newContainer .bottom .right form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-around;
}
.new .newContainer .top .right form .formInput,
.new .newContainer .bottom .right form .formInput {
  width: 40%;
}
.new .newContainer .top .right form .formInput label,
.new .newContainer .bottom .right form .formInput label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.new .newContainer .top .right form .formInput label .icon,
.new .newContainer .bottom .right form .formInput label .icon {
  cursor: pointer;
}
.new .newContainer .top .right form .formInput input,
.new .newContainer .bottom .right form .formInput input {
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid gray;
}
.new .newContainer .top .right form button,
.new .newContainer .bottom .right form button {
  width: 150px;
  padding: 10px;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.single {
  display: flex;
  position: relative;
}
.single .singleContainer {
  flex: 6;
}
.single .singleContainer .top {
  margin-top: 3.5rem;
  padding: 20px;
  display: flex;
  gap: 20px;
  width: 86%;
  margin-left: auto;
}
.single .singleContainer .top .left {
  flex: 1;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  position: relative;
}
.single .singleContainer .top .left .editButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 12px;
  color: #7451f8;
  background-color: rgba(117, 81, 248, 0.0941176471);
  cursor: pointer;
  border-radius: 0px 0px 0px 5px;
}
.single .singleContainer .top .left .item {
  display: flex;
  gap: 20px;
}
.single .singleContainer .top .left .item .itemImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.single .singleContainer .top .left .item .details .itemTitle {
  margin-bottom: 10px;
  color: #555;
}
.single .singleContainer .top .left .item .details .detailItem {
  margin-bottom: 10px;
  font-size: 14px;
}
.single .singleContainer .top .left .item .details .detailItem .itemKey {
  font-weight: bold;
  color: gray;
  margin-right: 5px;
}
.single .singleContainer .top .left .item .details .detailItem .itemValue {
  font-weight: 300;
}
.single .singleContainer .top .right {
  flex: 2;
}
.single .singleContainer .bottom {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 10px 20px;
  width: 82%;
  margin-left: auto;
}
.single .singleContainer .title {
  font-size: 16px;
  color: lightgray;
  margin-bottom: 20px;
}

.home {
  display: flex;
  position: relative;
}
.home .homeContainerUser {
  flex: 6;
  max-width: 85%;
  margin: auto;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .home .homeContainerUser {
    min-width: 95%;
    max-width: 100%;
  }
}
.home .homeContainerUser .widgets,
.home .homeContainerUser .charts {
  margin-top: 4rem;
  display: flex;
  padding: 20px;
  gap: 20px;
}
.home .homeContainerUser .charts {
  padding: 5px 20px;
}
.home .homeContainerUser .listContainer {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  margin-top: 4rem;
  padding: 20px;
  background-color: rgb(195, 195, 195);
}
@media only screen and (max-width: 960px) {
  .home .homeContainerUser .listContainer {
    margin-top: 1rem;
    padding: 5px;
  }
  .home .homeContainerUser .listContainer .css-vzjw0z-MuiTableCell-root {
    padding: 6px;
    line-height: normal;
    font-size: 100%;
  }
  .home .homeContainerUser .listContainer .css-1ygcj2i-MuiTableCell-root {
    padding: 10px 4px;
    line-height: 100% !important;
  }
  .home .homeContainerUser .listContainer .user_tableCell {
    font-size: 12px !important;
  }
}
.home .homeContainerUser .listContainer .listTitle {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}

.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);
}
.app.dark .chartGrid {
  stroke: rgba(228, 228, 228, 0.219);
}
.app.dark .table {
  background-color: #111;
}
.app.dark .table .tableCell {
  color: gray;
}
.app.dark .navbar {
  border-color: #333;
  background-color: #111;
  color: rgb(156, 156, 156);
}
.app.dark .navbar .search {
  border-color: gray;
}
.app.dark .sidebar {
  background-color: #111;
  border-color: #333;
}
.app.dark .sidebar .top .logo {
  color: #999;
}
.app.dark .sidebar hr {
  border-color: #333;
}
.app.dark .sidebar ul li:hover {
  background-color: #333;
}
.app.dark .sidebar ul li .icon,
.app.dark .sidebar ul li span {
  color: #999;
}
.app.dark .datatable .datagrid {
  color: gray;
  border: none;
}
.app.dark .datatable .datagrid .viewButton,
.app.dark .datatable .datagrid .deleteButton,
.app.dark .datatable .datagrid .cellWithStatus {
  color: gray;
  border: none;
}
.app.dark form {
  background-color: transparent !important;
}
.app.dark form .search_dropdown {
  border: 1px solid #696969;
  border-radius: 0.5rem;
}
.app.dark form .search_dropdown .css-qc6sy-singleValue {
  color: #aeaeae;
}
.app.dark form input {
  background-color: transparent !important;
  border: 1px solid !important;
  color: #aeaeae !important;
}
.app.dark .sidebar-item.plain {
  color: #999;
  text-decoration: none;
}
.app.dark .css-1s2u09g-control {
  background-color: #111;
  color: #ccc !important;
}
.app.dark .css-1s2u09g-control:focus {
  background-color: #111;
  color: #ccc !important;
}
.app.dark .css-319lph-ValueContainer {
  background-color: #111;
  color: #ccc !important;
}
.app.dark .css-319lph-ValueContainer:active, .app.dark .css-319lph-ValueContainer:visited, .app.dark .css-319lph-ValueContainer:active .app.dark .css-319lph-ValueContainer:focus {
  background-color: #111;
  color: #ccc !important;
}
.app.dark .css-1pahdxg-control {
  border-color: #333;
  background-color: #111;
  color: rgb(156, 156, 156);
}
.app.dark .css-6j8wv5-Input {
  color: #ccc !important;
}/*# sourceMappingURL=main.css.map */