/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Tiro+Bangla:ital@0;1&display=swap");
* {
  box-sizing: border-box;
}
:root {
  --td-width-view: 150px;
  --td-width-print: 100px;
  --print-font-p-size: 11px;
  --print-font-t-size: 14px;
  --view-font-p-size: 14px;
  --view-font-t-size: 21px;
}

body {
  font-family: "Tiro Bangla", "Times New Roman" !important;
  /* display: contents; */
  justify-content: center;
  margin: 0;
  padding: 0;
  // background-color: #d8d6d6;
}
.print_grid {
  display: grid;
  grid-template-columns: auto 14rem;
  width: 85%;
  // margin-left:auto;
  // margin-top: 5rem;
  position: relative;
}
.center_invoice {
  display: flex;
  justify-content: center;
  background-color: #9a9a9a;
  overflow-y: scroll;
}
.print_option {
  position: relative;
}
.fixed_button {
  position: fixed;
  top: 50%;
  right: 6rem;
}
/* MAIN SECTION CSS START */
#main-section {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: white;
}
.power-bill {
  padding: 10px 30px;
  margin: 0 auto;
  /* background-color: black;
    color: white; */
  width: 150px;
}
td {
  padding-left: 4px;
}
/* MAIN SECTION CSS END */
/* TOP SECTION CSS START */
#top-section {
  margin-top: 20px;
  text-align: center;
}
#top-section > p {
  margin: 0;
  padding: 0;
  font-size: 13px;
}
#top-section > h4 {
  padding: 0;
  margin: 0;
}
/* TOP SECTION CSS END */
/* CUSTOMER SECTION CSS START */

.customer-info-top-table,
.customer-info-center-table,
.customer-info-bottom-table {
  border-collapse: collapse;
}
.info {
  font-size: 13px;
}
/* .customer-info-table , tr{
    border: 2px solid red;
} */
/* CUSTOMER SECTION CSS END */
/* OFFICE COPY SECTION CSS START */
.office-copy-table,
tr,
td {
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 13px;
}

/* OFFICE COPY SECTION CSS END */

// @media print {
//   .pagebreak {
//   }
// }
.print-footer {
  display: none;
  // width: 100%;
  // text-align: center;
  // position: fixed;
  // bottom: 0;
  // background-color: #f1f1f1;
  // padding: 10px;
  // border-top: 1px solid #ccc;
}
@media print {
  #main-section {
    width: 100%;
    /* height: 595px; */
    // background-color: white;
    background-image: url("https://i.ibb.co.com/n0RjmDL/nogor.png");
    background-position: center center;
    // background-size: ;
    background-repeat: no-repeat;
    page-break-inside: avoid;
  }
  .center_invoice {
    background-color: white !important;
  }
  .print-footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto; /* Adjust height if needed */
    background-color: rgba(255, 255, 255, 0.894);
    padding: 4px;
    text-align: center;
    page-break-after: always; /* Ensure it's printed on each page */
  }

  .css-1ygcj2i-MuiTableCell-root {
    padding: 8px 4px !important;
    font-size: 10px !important;
  }
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
    background-color: transparent !important;
    // background-image: url('cinnomul-logo.png');
    width: 100%;
  }
  /* .css-1ygcj2i-MuiTableCell-root{

  } */
  /* .css-quj9j6-MuiTable-root {
    min-width: none !important;
  } */
}
