.sidebar {
  position: fixed;
  flex: 0 0 20%;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: white;
  max-width: 20%;
  top: 0;
  left: 0;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #6439ff;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;

      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #ece8ff;
        }

        .icon {
          font-size: 2rem;
          color: #888;
        }
        .sidebaritem {
          flex-direction: row;
          display: flex;
          background-color: red;
          justify-content: space-between;

          &:hover {
            background-color: white;
          }
        }

        span {
          font-size: 2rem;
          font-weight: 600;
          color: rgb(45, 42, 42);
          // color: rgb(34, 30, 30);
          margin-left: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .dark {
      color: w;
    }
    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
    }
  }
}
