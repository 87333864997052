.table {
  .cellWrapper {
    display: flex;
    align-items: center;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    &.true {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }
    &.false {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
  }
}

.tableCell_actions {
  text-align: center !important;
}

// .tableForUser {
//   width: 100%;
//   height: 15rem;
//   background-color: rgb(198, 191, 191);
//   border-spacing: 0;

//   .trUser {
//     // border: none;
//     padding: 0 10px;

//     .tdCell {
//       // border: none;
//       font-size: 1rem;
//       // padding: 0.5px;
//     }
//   }
// }
