@import url("https://fonts.googleapis.com/css2?family=Tiro+Bangla&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --color_red: #ba2014;
  --color_green: #2363b0;
  // --color_green_light:rgb(8, 148, 8);
}
body {
  font-family: "Tiro Bangla", serif !important;
  font-style: normal;
  font-size: 1em !important;
}
