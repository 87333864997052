.sidebar {
  flex: 0 0 15%;
  // width: 15%;
  // flex-shrink: 0;
  // background-color: rgba(22, 22, 22, 1);
  height: 100%;
  width: 15%;
  overflow: auto;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #6439ff;
    }
  }
}

.sidebar-item {
  padding: 0.75rem 1rem;
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
}
.sidebar-item:hover {
  background-color: rgba(49, 59, 195, 0.1);
}

.sidebar-title {
  pointer-events: visible;
  cursor: pointer;
  display: flex;
  font-size: 1.3em;
  padding: 1rem 0;
  justify-content: space-between;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open > .sidebar-content {
  height: auto;
}
.sidebar-item.plain {
  // color: #fff;
  font-size: 1.2em;
  color: #000;
  text-decoration: none;
}

.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}
